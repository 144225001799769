import React from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// Polyfills
// import "react-app-polyfill/ie11";
// import "core-js/stable";
import i18n from "./I18n";
import store from "redux/Store";
import SdkProvider from "providers/client-sdk-providers";
import ThemeProvider from "providers/ThemeProvider";
import SocketProvider from "providers/SocketProvider";
import WindowSizeProvider from "providers/WindowSizeProvider";
import { Provider as RollbarProvider } from "@rollbar/react";
import { type Configuration } from "rollbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "rc-slider/assets/index.css";
import "./index.css";

// Import the functions you need from the SDKs you need

import firebase from "firebase/compat/app";
import Main from "routes/main";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM ?? "",
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: import.meta.env.MODE ?? "unknown",
  reportLevel: "error",
  autoInstrument: {
    log: false,
  },

  /* checkIgnore: (isUncaught, args, item) => {
    console.log({ isUncaught, args, item });
    return false;
  }, */

  ignoredMessages: [
    "(unknown): Script error.", // NOTE - bkash
    "Script error.", // NOTE - bkash
    "TypeError: window.webViewJSBridge.onResume is not a function. (In 'window.webViewJSBridge.onResume()', 'window.webViewJSBridge.onResume' is undefined)", // NOTE - bkash
    "window.webViewJSBridge.onResume is not a function. (In 'window.webViewJSBridge.onResume()', 'window.webViewJSBridge.onResume' is undefined)", // NOTE - bkash
  ],
};

const container = document.getElementById("root");
if (!container) {
  throw new Error("The element #portal wasn't found");
}
const root = createRoot(container);

root.render(
  <RollbarProvider config={rollbarConfig}>
    <Provider store={store}>
      <SdkProvider>
        <SocketProvider>
          <WindowSizeProvider>
            <ThemeProvider>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <Main />
                </BrowserRouter>
              </I18nextProvider>
            </ThemeProvider>
          </WindowSizeProvider>
        </SocketProvider>
      </SdkProvider>
    </Provider>
  </RollbarProvider>,
);
