import { fetchActiveTournaments, hideLoginPopup } from "actions";
import { APP_SLUGS } from "constants/constants";
import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import { setNavigationToTournamentDone } from "redux/reducers/uiReducer";
import LoginPopUpHandler from "routes/auth/components/LoginPopupHandler";
import FirebasePushNotificationComponent from "components/common/FirebasePushNotificationComponent";
import HeartBubblies from "components/feature-specific/heart-bubbles/HeartBubblies";
import LeaderboardPopup from "components/feature-specific/leaderboard/LeaderboardPopUp/LeaderboardPopup";
import CampaignMessageModal from "components/feature-specific/Modals/CampaignMessageModal";
import CongratsModal from "components/common/Modals/CongratsModal";
import DailyLoginModal from "components/feature-specific/daily-login/DailyLoginModal";
import FullScreenAdModal from "components/feature-specific/advertisement/modals/FullScreenAdModal";
import PaymentLoaderModal from "components/feature-specific/payment/PaymentModal/PaymentLoaderModal";
import PaymentModalProvider from "components/feature-specific/payment/PaymentModal/PaymentModalProvider";
import RewardPointModal from "components/feature-specific/Modals/RewardPointModal";
import TeamSelectionModal from "components/feature-specific/team/TeamSelectionModal";
import SubscriptionModalProvider from "components/feature-specific/subscription/modals/SubscriptionPackageModal/SubscriptionModalProvider";
import WinnerNotificationPopUp from "components/feature-specific/notification/WinnerNotificationPopUP";
import PaymentLoading from "components/feature-specific/payment/PaymentLoading";
import SnowFlakes from "components/feature-specific/snow-flake/SnowFlakes";
// import SpinningWheel from "Shared/Components/SpinningWheel";
import TournamentListPopUp from "components/feature-specific/tournament/TournamentListPopUp";
import useRankingButton from "hooks/useRankingButton";
import NotificationProvider from "providers/NotificationProvider";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { decodeSearchDataFromURL, findTournamentById, getQueryParamValue } from "utils";
import { isBkash, isOoredoo, isTng } from "utils/applicationSlug";
import { SpinWheel } from "components/feature-specific/spin-wheel";
import { hideLeaderboardPopup, showLeaderboardPopup } from "redux/reducers/layoutReducer";
import { useSearchParams } from "react-router-dom";

export const BaseModalLoader = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1022px)" });
  const dispatch = useAppDispatch();
  const { playGameButtonClick, loginPopupVisibility } = useAppSelector((state: any) => state.layout);
  const { application, user, campaigns, country } = useAppSelector((reduxState: any) => reduxState.common);
  const { activeTournamentCount, allTournaments } = useAppSelector((state: any) => state.tournaments);
  const { tournamentNavigationStatus, messageShown } = useAppSelector((state: any) => state.ui);
  const [campaignMessageModalVisible, setCampaignMessageModalVisibility] = useState(false);
  const [shouldShowCampaignMessageModal, setShouldShowCampaignMessageModal] = useState<boolean>(false);
  const [dailyLoginRewardModalVisible, setDailyLoginRewardModalVisibility] = useState<boolean>(false);
  const [dailyLoginRewardModalLoading, setDailyLoginRewardModalLoading] = useState<boolean>(true);
  // const { search: queryParams } = useLocation();

  const [tokenModalVisibility, setTokenModalVisibility] = useState(false);
  const hideTokenModal = useCallback(() => setTokenModalVisibility(false), []);

  const isTournamentPage = location.pathname === "/tournament";
  const enablePushNotification = application?.enable_push_notification;
  const enableSnowfall = application?.enable_snowfall;
  const enableBubbleHeart = application?.enable_bubble_heart;
  // if entry fee currency is f-secure && application is TNG then payment confirmation
  // will not be visible but it will follow normal payment.
  const directPaymentEnabled = isBkash || isOoredoo || isTng;

  const hideCampaignMessageModal = useCallback(() => setCampaignMessageModalVisibility(false), []);

  const [showHeartBubbles, setShowHeartBubbles] = useState(false);
  // const [showSpinningWheel, setShowSpinningWheel] = useState<boolean>(true);
  useEffect(() => {
    // Set a timeout to enable snowfall after 3 seconds
    const timeoutId = setTimeout(() => {
      setShowHeartBubbles(true);
    }, 3000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Run this effect only once on mount

  const { campaign = {} } = campaigns || {};
  const { message_template: campaignMessageTemplate } = campaign || {};
  const rewardMessageTemplate = campaign?.reward?.message_template;
  const enableCampaignMessageTemplate =
    campaign &&
    !campaign?.minigamereward?.wheel_configuration?.id &&
    (campaignMessageTemplate || rewardMessageTemplate);

  useEffect(() => {
    if (!messageShown) {
      setShouldShowCampaignMessageModal(true);
    }
  }, [messageShown]);

  useEffect(() => {
    if (shouldShowCampaignMessageModal) {
      // if (!dailyLoginRewardModalLoading && !dailyLoginRewardModalVisible) {
      // TODO: ^ Uncomment if you want to show Welcome banner after closing Daily Reward instead of in the back
      if (!dailyLoginRewardModalLoading) {
        // TODO: Comment ^ line if uncomment ^^ line
        setCampaignMessageModalVisibility(true);
        setShouldShowCampaignMessageModal(false);
      }
    }
  }, [dailyLoginRewardModalLoading, dailyLoginRewardModalVisible, shouldShowCampaignMessageModal]);

  const { search } = useLocation();
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (application && country && user) {
      const { tournament: tournamentIdFromURL, open_prize, callback } = decodeSearchDataFromURL();
      if (tournamentIdFromURL) {
        dispatch(fetchActiveTournaments(application?.slug, country) as any).then(() => {
          if (callback && directPaymentEnabled) {
            dispatch(hideLeaderboardPopup());
          } else {
            dispatch(
              showLeaderboardPopup({
                tournament: +tournamentIdFromURL,
                openPrize: open_prize,
              }),
            );
          }
        });
        // setSearchParams({});
        // navigate(pathname, { replace: true });
        /* setTimeout(() => {
          navigate(pathname, { replace: true });
        }, 4000); */
        // setTimeout(() => {
          // navigate(-1);
        // }, 5000);
      } else {
        dispatch(hideLeaderboardPopup());
      }
    }
    return () => {
      dispatch(hideLeaderboardPopup());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.slug, country, dispatch, isDesktop, directPaymentEnabled, application, user, search]);

  useEffect(() => {
    if (application?.slug?.toLowerCase() === APP_SLUGS.SABAY?.toLowerCase()) {
      dispatch(hideLoginPopup() as any);
    } else if (application?.slug?.toLowerCase() === APP_SLUGS.DEMO?.toLowerCase()) {
      dispatch(hideLoginPopup() as any);
    } else if (application?.slug === "viva" || application?.slug === "pepsico-demo") {
      dispatch(hideLoginPopup() as any);
    } else if (application?.slug?.toLowerCase() === APP_SLUGS.EL_TIEMPO?.toLowerCase()) {
      dispatch(hideLoginPopup() as any);
    }
  }, [dispatch, application]);

  useEffect(() => {
    if (loginPopupVisibility) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    }
    return () => {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    };
  }, [loginPopupVisibility]);

  const { openLeaderBoardPopUp } = useRankingButton();

  useEffect(() => {
    if (!tournamentNavigationStatus) {
      const navigateTournamentId = getQueryParamValue("navigateToTournament");
      if (navigateTournamentId) {
        const tournament = findTournamentById(parseInt(navigateTournamentId), allTournaments);
        if (tournament) {
          // history.push(`/games/${tournament.game}`);
          // history.push({ search: `tournament=${tournament.id}` });
          openLeaderBoardPopUp(tournament, true);
          dispatch(setNavigationToTournamentDone());
        }
      }
    }
  }, [dispatch, activeTournamentCount, allTournaments, application, tournamentNavigationStatus, openLeaderBoardPopUp]);

  useEffect(() => {
    let ooredooCallbackTournament: any = localStorage && localStorage.getItem("tournament-callback");
    ooredooCallbackTournament = ooredooCallbackTournament && JSON.parse(ooredooCallbackTournament);

    setTimeout(() => {
      if (window.location.toString().includes("ordid")) {
        openLeaderBoardPopUp(ooredooCallbackTournament, true);
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.toString().includes("ordid"), isOoredoo]);

  useEffect(() => {
    if (!messageShown) {
      setTokenModalVisibility(true);
    }
  }, [messageShown]);

  return (
    <>
      <LoginPopUpHandler />
      <FullScreenAdModal />
      {user && user.inbox.length !== 0 && <CongratsModal show={tokenModalVisibility} onHide={hideTokenModal} />}
      <DailyLoginModal
        onShow={() => setDailyLoginRewardModalVisibility(true)}
        onHide={() => setDailyLoginRewardModalVisibility(false)}
        setLoadingStatus={(status: boolean) => {
          setDailyLoginRewardModalLoading(status);
        }}
      />
      {enableCampaignMessageTemplate && (
        <CampaignMessageModal
          show={campaignMessageModalVisible}
          onHide={hideCampaignMessageModal}
          message_template={campaignMessageTemplate || rewardMessageTemplate}
        />
      )}
      {application && <NotificationProvider />}
      <PaymentLoading />
      {isTng && playGameButtonClick && <PaymentLoaderModal show={true} />}
      {application?.applicationsetting?.team_base_application && <TeamSelectionModal />}
      {directPaymentEnabled && <PaymentModalProvider />}
      {enablePushNotification && <FirebasePushNotificationComponent />}
      <RewardPointModal />
      <ToastContainer />
      {application?.enable_winner_popup_notification && user && <WinnerNotificationPopUp />}
      <LeaderboardPopup />
      <TournamentListPopUp />
      {isOoredoo && <SubscriptionModalProvider />}
      {enableSnowfall && location.pathname === "/tournament" && <SnowFlakes />}
      {showHeartBubbles && enableBubbleHeart && location.pathname === "/tournament" && <HeartBubblies />}
      {isTournamentPage && <SpinWheel />}
      {/*  {showSpinningWheel && (
        <SpinningWheel
          onClose={() => {
            setShowSpinningWheel(false);
          }}
        />
      )} */}
    </>
  );
};
