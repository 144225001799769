import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import SpinWheelBtn from "./SpinWheelBtn";
import SpinReward from "./SpinReward";
import Wheel from "./Wheel";
import ConfettiAnimation from "components/common/Animation/ConfettiAnimation";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { css } from "@emotion/react";
import { ReactComponent as SadIcon } from "assets/images/subscription/sad_icon.svg";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { fetchStatusPoints } from "redux/reducers/statusPointReducer";
import { Trans } from "react-i18next";
import api from "actions/api";
import {
  addSpinBalance,
  deductSpinBalance,
  fetchSpinTheWheelBalance,
  setSpinBalance,
} from "redux/reducers/wheelReducer";
import WheelPaymentService from "services/payment/WheelPaymentService";
import { isOoredoo } from "utils/applicationSlug";

type WheelHandle = {
  forceSpin: () => void;
};

const zIndexModal = 999998;

export const SpinWheel = () => {
  type WheelOption = { id: string };
  const [showModal, setShowModal] = useState(false);
  const [showWheel, setShowWheel] = useState(false);
  const [showSpinReward, setShowSpinReward] = useState(false);
  const [prize, setPrize] = useState<any>({});
  const [messageTemplate, setMessageTemplate] = useState({});
  const [numberOfTries, setNumberOfTries] = useState(0);
  const [wheelOptions, setWheelOptions] = useState<WheelOption[]>([]);
  const [stopAngle, setStopAngle] = useState(0);
  const [isError, setIsError] = useState(false);
  const { country, user, application, campaigns } = useAppSelector((state: any) => state.common) || { campaigns: null };
  const { campaign } = campaigns || {};
  const { minigamereward } = campaign || {};
  const id = minigamereward?.wheel_configuration?.id;
  const [endTime, setEndTime] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { spin_balance } = useAppSelector((state: any) => state.spinWheelInfo);
  const wheelRef = useRef<WheelHandle>(null);

  useEffect(() => {
    if (showModal) {
      dispatch(fetchSpinTheWheelBalance());
    }
  }, [dispatch, showModal]);

  const onForceSpin = () => {
    if (wheelRef.current) {
      wheelRef.current.forceSpin();
    }
  };

  const fetchSpinWheelData = useCallback(() => {
    const fetchData = async () => {
      try {
        if (id && user) {
          const response = await api.get(`tournaments/campaigns/wheel-configs/${id}/`);

          const fetchedWheelOptions = response?.data?.spinning_wheel_options || [];
          setWheelOptions(fetchedWheelOptions);
          const date = minigamereward?.next_reset;
          if (minigamereward?.number_of_tries >= 0) {
            dispatch(setSpinBalance(minigamereward?.number_of_tries || 0));
            setNumberOfTries(minigamereward?.number_of_tries || 0);
            // dispatch(setSpinBalance(minigamereward?.number_of_tries + spin_balance));
            // setNumberOfTries(minigamereward?.number_of_tries + spin_balance);
          }
          // setNumberOfTries(minigamereward?.number_of_tries || 0);
          if (date) {
            setEndTime(new Date(date).toISOString());
          }
        }
      } catch (error) {
        // console.error("Error fetching spin wheel data :", error); 
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, minigamereward, user]);

  useEffect(() => {
    if (user) {
      fetchSpinWheelData();
    }
  }, [fetchSpinWheelData, user]);

  const decreaseNumberOfTries = () => {
    numberOfTries >= 1 && setNumberOfTries(numberOfTries - 1);
    spin_balance >= 1 && dispatch(deductSpinBalance());
  };

  const increaseNumberOfTries = () => {
    setNumberOfTries(numberOfTries + 1);
    dispatch(addSpinBalance());
  };

  const spinWheelApi = () => {
    // if (numberOfTries === 0) return Promise.resolve();
    if (id && country && application.slug) {
      // setNumberOfTries(numberOfTries - 1);
      decreaseNumberOfTries();
      return api
        .post("tournaments/campaigns/spin-wheel/", {
          config_id: id,
          country,
          application: application.slug,
        })
        .then(response => {
          const result = response?.data?.result;

          setMessageTemplate(response?.data?.message_template?.message);

          // const remainingTries = result?.remaining_tries || 0;
          // setNumberOfTries(remainingTries);
          const stopIndex = wheelOptions?.findIndex(item => item?.id === result?.stop_at) + 1;
          setStopAngle(stopIndex * (360 / wheelOptions.length));
          const rewards = wheelOptions?.find(item => item?.id === result?.stop_at);
          if (!rewards) {
            setStopAngle(0);
            setIsError(true);
            setShowWheel(false);
            setShowSpinReward(false);
            return;
          }
          if (rewards) {
            setPrize(rewards);
          }
        })
        .catch(() => {
          setIsError(true);
          setShowWheel(false);
          setShowSpinReward(false);
          fetchSpinWheelData();
          increaseNumberOfTries();
          // console.error("Error in spinWheelApi:", error);
        });
    }
    return;
  };

  const toggleSpinWheelModal = () => {
    setIsError(false);
    setShowSpinReward(false);
    setShowWheel(true);
    setShowModal(prevShowModal => {
      if (!prevShowModal) {
        setShowSpinReward(false);
      }
      return !prevShowModal;
    });
  };

  const updateTicketAndCoin = () => {
    if (prize.type === "TICKET") {
      dispatch(fetchStatusPoints());
    } else if (prize.type === "COIN") {
      dispatch(fetchTokenBalance(country));
    }
  };

  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const purchaseSpinWheel = async () => {
    const wheelPaymentService = new WheelPaymentService({ country, application });
    if (isOoredoo) {
      try {
        setPurchaseLoading(true);
        await wheelPaymentService.initiatePayment({
          extraData: {
            spin_quantity: 1,
            payment_type: "ooredoo_account",
          },
        });
        // dispatch(fetchSpinTheWheelBalance());
        onForceSpin();
      } catch (error) {
        console.error("Error initiating payment:", error);
      } finally {
        setPurchaseLoading(false);
      }
    } else {
      console.error("Not implemented yet");
    }
  };

  if (!id || !campaigns) return null;

  return (
    <>
      <Modal
        backdropClassName="daily-login-reward_modal--backdrop"
        show={showModal}
        onHide={toggleSpinWheelModal}
        css={modalStyle}
        centered
      >
        <div className="confetti">{showSpinReward && <ConfettiAnimation />}</div>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Spin </span> <span>the</span> <br />
            <span>Wheel</span>
          </Modal.Title>
        </Modal.Header>

        <motion.div animate={{ opacity: showWheel ? 1 : 0 }}>
          {showWheel && (
            <Wheel
              ref={wheelRef}
              setPrize={setPrize}
              spinWheelApi={spinWheelApi}
              stopAngle={stopAngle}
              numberOfTries={numberOfTries}
              wheelOptions={wheelOptions}
              setShowWheel={setShowWheel}
              setShowSpinReward={setShowSpinReward}
              updateTicketAndCoin={updateTicketAndCoin}
              purchaseSpin={purchaseSpinWheel}
              loading={purchaseLoading}
            />
          )}
        </motion.div>

        <motion.div animate={{ opacity: showSpinReward ? 1 : 0 }}>
          {showSpinReward && (
            <SpinReward
              show={showSpinReward}
              prize={prize}
              message={messageTemplate}
              setPrize={setPrize}
              setShowWheel={setShowWheel}
              setShowSpinReward={setShowSpinReward}
            />
          )}
        </motion.div>

        {isError && (
          <Modal.Body>
            <div className="error-container">
              <SadIcon className="error-icon" />
              <h4>
                <Trans i18nKey="something-went">Something went wrong try again</Trans>
              </h4>
            </div>
          </Modal.Body>
        )}
      </Modal>

      <SpinWheelBtn
        handleShow={toggleSpinWheelModal}
        showModal={showModal}
        timer={endTime}
        fetchSpinWheelData={fetchSpinWheelData}
      />
    </>
  );
};

const modalStyle = (theme: ITheme) => css`
  z-index: ${zIndexModal} !important;
  .modal-content {
    background: ${theme.background.primary} !important;
  }
  .modal-header {
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 0;
    border-bottom: 0;
    z-index: 99;
    .btn-close {
      position: absolute;
      top: 15px;
      left: 10px;
      border: 0;
      cursor: pointer;
    }
  }
  .spin-bg {
    position: absolute;
    top: -85px;
    right: 10px;
    width: 96%;
    height: 100%;

    #color-1 {
      fill: ${theme.button.tertiaryButtonBackground};
    }
    #color-2 {
      fill: ${theme.button.primaryGradientStart};
    }
  }
  .modal-body {
    padding-top: 5px;
  }
  .modal-title {
    padding: 10px 5px;
    z-index: 99;
    span {
      font-family: "Spicy Rice", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 28px;
      line-height: 0px;
      &:first-of-type {
        color: ${theme.button.primaryGradientStart};
      }
      &:nth-of-type(2) {
        font-size: 20px;
      }
      &:nth-of-type(3) {
        color: ${theme.button.tertiaryButtonBackground};
        margin-left: 20px;
      }
    }
  }
  .modal-content {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 10px;
    background: ${theme.background.primary};
    box-shadow: 1px 3px 29px 0px rgba(150, 150, 150, 0.21);
  }
  .spin-left {
    width: 140px;
    padding: 5px;
    background-color: ${theme.text.tertiaryTextColor};
    border-radius: 14px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    color: ${theme.button.buttonTextColor};
    text-align: center;
    position: absolute;
    bottom: -50px;
    left: 30%;
  }
  .error-container {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 1.75rem;
      text-align: center;
      color: #000 !important;
      font-weight: 600;
      max-width: 200px;
    }
    .error-icon {
      height: 150px;
      width: 150px;
      /* webkit-aspect-ratio: 1; */
      border-radius: 50%;
      padding: 20px;
    }
  }
`;
