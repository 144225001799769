import React from "react";
import { css } from "@emotion/react";
import { useAppSelector } from "redux/Store";
import cn from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { avatars, defaultAvatar } from "utils/avatars";
import { klookTranslatePrize, monthNames } from "utils";
import EntryFee from "../../../common/EntryFee";
import AvatarFrame from "components/feature-specific/avatar/desktop/AvatarFrame";
import { isBountie, isKlook } from "utils/applicationSlug";

const textCommonStyle = (theme: ITheme) => css`
  font-size: 14px;
  background-color: ${theme.foreground.primary};
  padding: 0.25rem 0.5rem;

  &.own-rank {
    color: ${theme.button.primaryGradientStart};
    .text-style-transaction {
      color: ${theme.button.primaryGradientStart};
    }
  }
  &.globe {
    color: #2c4763;
    .text-style-transaction {
      color: #2c4763;
    }
  }
  .text-style-transaction {
    font-size: 11px;
    color: ${theme.text.secondary};
  }
  .avatar-wrapper {
    position: relative;
    width: 30px;
    height: 30px;
  }
  .avatar-container-style {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: ${theme.foreground.primary};
    box-shadow: 0 0 12px rgba(80, 80, 80, 0.15);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .profile_avatar_frame {
    position: absolute;
    width: calc(100% * 1.25);
    height: calc(100% * 1.25);
    top: -4px;
    left: -4px;
  }
`;

const digitizeNumber = (n: number) => (n > 9 ? `${n}` : `0${n}`);

interface IRankListItemProps {
  score: any;
  prizes?: any[];
  displayPrize: boolean;
  checkOwnRank?: boolean;
  OwnAvatar?: boolean;
  tournament: ITournament;
  currentUser?: boolean;
}

const RankListItem = ({ score, prizes = [], displayPrize, checkOwnRank = true, tournament }: IRankListItemProps) => {
  const { participants } = useAppSelector((state: any) => state.userTournaments);
  const { application, user, country } = useAppSelector((state: any) => state.common);
  const { isDesktop } = useAppSelector((state: any) => state.layout);

  const prize = prizes.find((obj: any) => score.rank >= obj.rank_from && score.rank <= obj.rank_to);
  const name = score?.participant?.extra_data?.name ?? "";

  const avatarURL = () => {
    if (score?.participant?.extra_data.profile_pic) {
      return score?.participant?.extra_data.profile_pic;
    } else {
      return avatars[score?.participant?.extra_data?.profile_pic_id] || defaultAvatar;
    }
  };

  const scoreTimestamp = score.timestamp ? new Date(score.timestamp) : null;

  const renderToolTip = (props: any) =>
    score.timestamp && scoreTimestamp ? (
      <Tooltip id={`tooltip-${score?.id}`} {...props}>
        <b>
          {digitizeNumber(scoreTimestamp.getHours())}:{digitizeNumber(scoreTimestamp.getMinutes())}:
          {digitizeNumber(scoreTimestamp.getSeconds())}
        </b>{" "}
        {scoreTimestamp.getDate()} {monthNames[scoreTimestamp.getMonth()]}
      </Tooltip>
    ) : (
      <div className="d-none" />
    );

  const enablePartnerBalance = application?.applicationsetting?.enable_partner_balance;

  const getPrize = () => {
    if (prize) {
      if (isBountie) {
        if (prize?.prize_type === "cash") {
          return `Gems ${prize?.prize * 100}`;
        } else if (prize?.prize_type === "bountie-token") {
          return `${"Tokens"} ${prize?.prize}`;
        } else {
          return `${prize?.prize_type?.charAt(0).toUpperCase() + prize?.prize_type?.slice(1) || ""} ${prize?.prize}`;
        }
      } else if (
        (!prize?.prize_type && tournament?.prize_calculation !== "automatic") ||
        prize?.prize_type === "cash"
      ) {
        if (enablePartnerBalance) {
          return <EntryFee entry_fee={prize?.prize} payMethod={prize?.prize_type} customClass="d-flex" />;
        }
        return `${tournament?.currency_symbol || ""}${" " + prize?.prize}`;
      } else if (prize?.prize_type === "physical-prize") {
        if (isKlook && prize?.klook_prize?.length > 0) {
          return klookTranslatePrize(prize, country);
        } else {
          return prize?.prize;
        }
      } else {
        switch (tournament?.prize_calculation) {
          case "automatic":
            return (
              <EntryFee
                entry_fee={prize?.prize}
                payMethod={tournament?.entry_fee_type}
                customClass="d-flex"
                tournamentFeeCurrency={tournament?.entry_fee_currency}
              />
            );
          default:
            return (
              <EntryFee
                entry_fee={prize?.prize}
                payMethod={prize?.prize_type}
                customClass="d-flex"
                tournamentFeeCurrency={tournament?.entry_fee_currency}
              />
            );
        }
      }
    } else {
      return "-";
    }
  };

  let frameUrl = score?.participant?.extra_data?.avatar_frame_url;

  if (score?.participant?.user_id === user?.id) {
    frameUrl = user?.extra_data?.avatar_frame_url;
  }

  return (
    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderToolTip}>
      <li
        className={cn(`li list-group-item position-relative ${application?.slug}`, {
          "own-rank": checkOwnRank && participants?.find((p: IParticipant) => p?.id === score?.participant?.id),
        })}
        css={textCommonStyle}
      >
        <div className="d-flex flex-row align-items-center py-2">
          <div
            className="col-2 col-items text-start ps-2 text-style-transaction body-md"
            style={{
              maxWidth: "10%",
            }}
          >
            {score.rank}
          </div>
          <div
            className="col col-items name-style"
            style={{
              maxWidth: displayPrize ? "45%" : "100%",
              display: "inline-flex",
              paddingLeft: !isDesktop ? "6%" : "1rem",
            }}
          >
            <div className="avatar-wrapper">
              <div
                className="avatar-container-style me-2 align-self-center"
                style={{ backgroundImage: `url(${avatarURL()})` }}
              />
              {frameUrl && <AvatarFrame url={frameUrl} />}
            </div>

            <span className="pt-2 ps-1 pe-1 pb-1 text-style-transaction text-wrap text-truncate body-md">
              {name.length < 10 ? name : `${name?.substring(0, 8)}..`}
            </span>
          </div>

          {displayPrize && (
            <div
              className={cn("col-3 col-items ps-2 text-start ps-3 text-style-transaction body-md", {
                "text-end": enablePartnerBalance,
              })}
              style={{
                maxWidth: "30%",
              }}
            >
              {getPrize()}
            </div>
          )}
          <div
            className="col-3 col-items text-end text-style-transaction pe-2 body-md"
            style={{
              maxWidth: "20%",
            }}
          >
            {score.value}
          </div>
        </div>
      </li>
    </OverlayTrigger>
  );
};

export default RankListItem;
