import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

export const Go3 = css`
  &.${APP_SLUGS.GO3} {
    .rank-btn,
    .text-play {
      background-color: #fa6200 !important;
      color: #ffffff !important;
    }

    .button-orange {
      button {
        color: #ffffff !important;
      }
    }

    .heroes {
      .first-part {
        background: #fa6200 !important;
      }
    }

    .rank-list-container {
      h5 {
        color: #ffffff !important;
      }
    }

    .header-container {
      .title {
        color: #ffffff !important;
      }
    }

    .activated-button {
      color: #fa6200 !important;
    }

    .top-bar-wrapper {
      .fontHeadStyle,
      .fontSubStyle {
        color: #fa6200 !important;
      }
    }

    .page-wrapper {
      .top-bar {
        .top-bar-top {
          background: #000000 !important;
          box-shadow: none !important;
        }
      }
    }

    .rank-list-card {
      .label-no-ranks {
        color: #fa6200 !important;
      }
    }

    .browse-popup {
      .contain-style {
        span {
          color: #fa6200 !important;
        }
      }
    }

    .custom-popup-container {
      .coming-soon-text,
      span {
        color: #fa6200 !important;
      }
    }

    .footer-content {
      background-color: #000000 !important;
    }

    .headerInfoWrapper {
      .no-info {
        color: #fa6200 !important;
      }
    }

    .mission-page-container {
      .section-header {
        color: #fa6200 !important;
      }
    }

    .single-game-card {
      .square-card {
        background-color: #e5e5e5 !important;
        .card-title,
        .timer-data {
          color: #000000 !important;
        }
      }
    }

    .progressbar_wrapper {
      .small_circle {
        border: 7px solid #e5e5e5 !important;
      }

      .RCP__track {
        stroke: #e5e5e5 !important;
      }
    }

    .progress_bottom_curve {
      background-color: #ffffff !important;
      .timer {
        .clock_icon {
          padding-bottom: 5px !important;
        }
      }
    }

    .helper_text {
      color: #000000 !important;
    }

    .active_progress_bottom_curve {
      background-color: #fa6200 !important;
    }

    .action_button {
      button {
        color: #000000 !important;
      }
    }

    .homepage-background {
      box-shadow: rgb(202 166 143) -1pt 5pt 5px 0px;
    }
  }
`;

export default Go3;
