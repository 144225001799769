import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import cn from "classnames";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Trans } from "react-i18next";
import { avatars } from "utils/avatars";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { updateUserAvatar } from "actions/updateUserAvatar";
// import { updateUserAvatar } from "actions";

type TUpdateAvatarFormProps = {
  onSubmit?: (e?: any) => void;
  hideTitle?: boolean;
};

export default function UpdateAvatarForm({ onSubmit, hideTitle = false }: TUpdateAvatarFormProps) {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAppSelector((state: any) => state.common);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (user?.extra_data?.profile_pic_id >= 0) setSelectedIndex(user?.extra_data?.profile_pic_id);
  }, [user]);

  const handleUpload = async () => {
    setSubmitting(true);
    try {
      await dispatch(updateUserAvatar(selectedIndex) as any);
    } catch (e) {
      //
    } finally {
      setSubmitting(false);
    }
  };

  const updateAvatars = () => {
    return (
      <div className="avatars-container mt-2">
        {avatars.map((pic: any, index: any) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            key={index}
            src={pic}
            alt={`Avatar-${index}`}
            style={{
              width: "18%",
              marginRight: "2%",
              marginBottom: "2%",
              borderRadius: "50px",
            }}
            className={cn({ "selected-avatar": selectedIndex === index })}
            onClick={() => setSelectedIndex(index)}
          />
        ))}
      </div>
    );
  };

  return (
    <div css={baseStyle} className="avatar-update-modal">
      {!hideTitle && (
        <Modal.Header closeButton id="contained-modal-title-vcenter" className="avatarStyle border-0 p-0 pb-3">
          <Modal.Title>
            <Trans i18nKey="avatar-list">Avatar List</Trans>
          </Modal.Title>
        </Modal.Header>
      )}

      {updateAvatars()}

      <Button
        disabled={submitting}
        onClick={() => {
          (async () => {
            await handleUpload();
            onSubmit && onSubmit();
          })();
        }}
        className="mt-2 update-button"
      >
        <Trans i18nKey="update">Update</Trans>
      </Button>
    </div>
  );
}

const baseStyle = (theme: ITheme) => css`
  .avatarStyle {
    color: ${theme.text.primary};
  }
  .selected-avatar {
    border-radius: 54px;
    border-style: solid;
    border-width: 6px;
    color: ${theme.button.primaryGradientStart};
  }
`;
